@import '../../../../assets/style/index'

.box
    width: 100%
    height: auto

    > div
        width: 100%
        min-height: $box_height

        border-bottom: none

        position: relative

        &:before
            content: ''
            position: absolute
            top: 0
            left: 50%
            transform: translateX(-50%)

            width: 100vw
            height: 100%

            border-bottom: .1rem solid $black

        &:nth-child(1)
            border-top: 0

            display: flex
            justify-content: center
            align-items: center


.years
    width: 100%
    height: 100%

    display: grid
    grid-template-columns: repeat(7, 1fr)

    * p
        font-size: 1.8rem
        position: relative

        @include md
            font-size: 1.6rem

        @include sm
            font-size: 1.4rem

    > div
        border-left: .1rem solid $black
        height: $box_height
        cursor: pointer

        display: flex
        justify-content: center
        align-items: center

        &:nth-child(1)
            border-left: none


.projects
    width: 100%
    height: auto

    * h1
        position: relative
        cursor: pointer

        @include md
            font-size: 1.6rem

        @include sm
            font-size: 1.4rem

    > div
        width: 100%
        min-height: $box_height

        position: relative

        &:before
            content: ''
            position: absolute
            top: 0
            left: 50%
            transform: translateX(-50%)

            width: 100vw
            height: 100%

            border-bottom: .1rem solid $primary

        &:nth-child(1)
            border-top: 0

.projectsTitle
    width: 100%
    height: $box_height
    display: flex
    justify-content: center
    align-items: center

.arrow
    position: absolute
    left: 105%
    top: 50%
    transform: translateY(-50%) rotate(90deg) !important


.arrowActive
    transform: translateY(-50%) rotate(-90deg) !important
    color: $primary