@import '../../assets/style/index'

.box
    width: 100%
    height: auto

    > div
        width: 100%
        height: $box_height

        position: relative

        &:before
            content: ''
            position: absolute
            top: 0
            left: 50%
            transform: translateX(-50%)

            width: 100vw
            height: 100%

            border-top: .1rem solid black

        &:nth-child(1)
            height: calc(#{$box_height} * 2)

            &:before
                border-top: none


        &:nth-child(2)
            border-top: 0
            display: flex
            justify-content: center
            align-items: center

        &:nth-last-child(1)
            border-top: none
            border-bottom: none

.boxSocial
    width: 100%
    height: auto

    color: $primary

    display: grid
    grid-template-columns: repeat(3, 1fr)

    @include sm
        grid-template-columns: 1fr

    > div
        border-left: .1rem solid $black
        display: flex
        justify-content: center
        align-items: center

        @include sm
            border-left: none
            border-top: .1rem solid $black
            height: $box_height

        &:nth-child(1)
            border-left: none

            @include sm
                border-top: none