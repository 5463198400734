@import '../../assets/style/index'

.box
    height: $box_height

    display: flex
    justify-content: space-between
    align-items: center

    position: relative

    &:before
        content: ''
        position: absolute
        top: 0
        left: 50%
        transform: translateX(-50%)

        width: 100vw
        height: 100%

        border-bottom: .1rem solid black

    > div
        height: 100%

        display: flex
        align-items: center
        justify-content: space-between

    * img
        height: 100%

.boxLogo
    width: 10rem
    height: 100%

    padding: 2.25rem 0

.active
    color: $primary


.boxLangText
    font-size: 1.5rem
    cursor: pointer
    position: relative
    z-index: 100

    &:hover
        color: $primary

.boxLangTextActive
    color: $primary

.boxBreakText
    margin: 0 .5rem

