@import '../../../../assets/style/index'

.box
    width: 100%
    height: auto

    > div
        width: 100%
        height: auto

        position: relative

        &:before
            content: ''
            position: absolute
            top: 0
            left: 50%
            transform: translateX(-50%)

            width: 100vw
            height: 100%

            border-bottom: .1rem solid black

        &:nth-child(1),
        &:nth-child(2)
            display: flex
            justify-content: center
            align-items: center

        &:nth-child(3)
            min-height: $box_height
            height: auto


.boxTitle
    padding: $margin 0

.about
    width: 100%
    min-height: 100%

    display: grid
    grid-template-columns: 40% 1fr

    @include md
        grid-template-columns: 45% 1fr

    @include sm
        grid-template-columns: 1fr


    > div
        border-left: .1rem solid $black
        padding: $margin

        @include sm
            border-left: none

        &:nth-child(1)
            border-left: none
            padding-right: $margin_big
            padding-left: 0

            @include md
                padding-right: $margin

        &:nth-child(2)
            padding-left: $margin_big
            padding-right: 0

            @include md
                padding-left: $margin

            @include sm
                padding-left: 0

            > div
                > p
                    margin-bottom: $margin

.aboutSections
    display: grid
    grid-template-rows: repeat(2, 1fr)

    position: relative

    &:before
        content: ''
        position: absolute
        top: 50%
        left: 0
        width: 100vw
        height: 0
        transform: translate(0, -50%)

        border-bottom: .1rem solid black

    @include sm
        &:before
            left: 50%
            transform: translate(-50%, -50%)


    > div:nth-child(1)
        padding-bottom: $margin
    > div:nth-child(2)
        padding-top: $margin


.aboutSection
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start

    > p
        white-space: pre-line

    > a
        @include sm
            margin: 0 auto

.aboutImage
    width: 100%
    height: 50rem

    background-color: $primary


.aboutImageContent
    @include sm
        display: none
