@import '../assets/style/index'

.box
    width: 100%
    height: auto

.boxContent
    width: 100%
    max-width: $max_width
    height: auto
    margin: 0 auto

    padding: 0 .5rem
