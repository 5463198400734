*,
*::after,
*::before
    margin: 0
    padding: 0
    box-sizing: inherit


html
    font-size: 62.5%
    font-family: $font
    scroll-behavior: smooth

body
    font-weight: 400
    line-height: 1.7
    color: $black
    overflow-x: hidden
    box-sizing: border-box
    background-color: $white

span,
textarea,
input,
select
    font-family: $font

a
    color: inherit
    font-size: 1.5rem

a,
a:link,
a:active
    text-decoration: none
    z-index: 500

ul, li
    list-style: none

h1, h2, h3
    font-weight: 400
    font-size: 1.6rem

p
    font-size: 1.5rem

button
    font-size: 1.2rem
    text-transform: uppercase
    color: $primary
    cursor: pointer

    background-color: transparent
    outline: none

    border: .1rem solid $black

    padding: .75rem $margin-big
    border-radius: 3rem

    position: relative
    z-index: 1000