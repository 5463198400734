@import breakpoints


//Layout Standard Width
$width: 100%
$max_width: 110rem
$box_height: 7.5rem
$margin: 3rem
$margin_big: calc(#{$margin} * 2)

//Layout Standard Height
$min_height: 100vh
$max_height: 90rem
$height: 100vh

// Colors
$white: #ffffff
$black: #222222
$primary: #5086a7


// Fonts
@font-face
    font-family: 'redhat'
    src: url('../fonts/Red_Hat_Mono/RedHatMono-VariableFont_wght.ttf') format("truetype")

$font: 'redhat'
