@import '../../../../../assets/style/index'

.box
    width: 100%
    height: auto

    position: relative
    z-index: 1000
    padding: $margin-big 0

    overflow-x: hidden

    * img
        width: 100%
        height: 100%

    > div
        min-height: $box_height
        margin-top: $margin

        &:nth-child(1)
            margin-top: 0

.boxFull
    width: 100%
    height: 100%
    padding-top: 57.6%
    position: relative
    border: .1rem solid $black
    background-color: $black


    > div
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0

        margin: auto


.boxGallery
    width: 100%
    height: auto

    display: flex
    justify-content: flex-start
    align-items: center

    overflow: auto

    position: relative
    z-index: 1000

    > div
        $width: calc(#{$box_height} * 4)
        width: $width
        min-width: $width
        height: auto

        margin-left: $margin

        &:hover
            opacity: .6
            cursor: pointer

        &:nth-child(1)
            margin-left: 0

        > div
            width: 100%
            background-color: $primary
            border: .1rem solid $black
            padding-top: 56.10%

.boxText
    white-space: pre-wrap

.boxAction
    display: flex
    justify-content: center
    align-items: center

.boxVideo
    width: 100%
    height: 100%
    position: relative

    iframe
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%






